
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { Demand } from '@/model/industryManagement/demandInformation'
import comtentLayout from '@/components/comtentLayout/index.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import {
  CheckCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
} from '@ant-design/icons-vue'
import demandApi from '@/axios/api/demand'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import Data from '@/model/common/Data'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    comtentLayout,
    PlusOutlined,
    CheckCircleOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    CloseCircleOutlined,
  },
  setup() {
    const route = useRoute()
    const demandDetailFormRef = ref()
    const pageData = reactive({
      demandDetailForm: {} as Demand,
      fileList: [] as Data[],
      current: 0,
      quoteStatus: '',
    })
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    pageData.demandDetailForm.isDevelop = 1
    const optionList = [
      { label: '重要紧急', value: 0 },
      { label: '重要不紧急', value: 1 },
      { label: '紧急不重要', value: 2 },
      { label: '不重要不紧急', value: 3 },
    ]
    const getDetail = () => {
      demandApi
        .GetByNo({ demandNo: route.params.id })
        .then((res) => {
          pageData.current = res.status as number
          if (res.status === 2 || res.status === 3) {
            pageData.current = 2
          } else if ((res.status as number) > 3) {
            pageData.current = (res.status as number) - 1
          } else {
            pageData.current = res.status as number
          }
          pageData.demandDetailForm = res
          if (pageData.demandDetailForm.quoteNo) {
            demandApi
              .GetSaAskpriceVOByQuoteNo({
                quoteNo: pageData.demandDetailForm.quoteNo,
                quoteNumber: '',
              })
              .then((res) => {
                pageData.quoteStatus =
                  res.istates === 0 ? '未处理' : res.istates === 1 ? '通知审核' : '已审核'
              })
              .catch((err) => {
                message.error(err)
              })
          }
          pageData.fileList = res.fileInfoList?.map((item) => {
            return {
              uid: item.id, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
              name: item.fullFileName, // 文件名
              url: `/api/file/preview?id=${item.id}`,
              status: 'done',
            }
          }) as Data[]
        })
        .catch((err) => {
          message.error(err)
        })
    }
    getDetail()
    const submit = () => {
      demandApi
        .DemandUpdate(pageData.demandDetailForm)
        .then(() => {
          if (pageData.demandDetailForm.status === 1) {
            let data = {
              id: pageData.demandDetailForm.id,
              industryStandard: pageData.demandDetailForm.industryStandard,
              lifeRequirements: pageData.demandDetailForm.lifeRequirements,
              mainMaterial: pageData.demandDetailForm.mainMaterial,
              bear: pageData.demandDetailForm.bear,
              processingRequired: pageData.demandDetailForm.processingRequired,
              operatingTemperature: pageData.demandDetailForm.operatingTemperature,
              storageRequired: pageData.demandDetailForm.storageRequired,
            }
            demandApi.UpdateEvaluate(data).then(() => {
              message.success('修改成功')
              getDetail()
            })
          } else {
            message.success('修改成功')
            getDetail()
          }
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const reset = () => {
      getDetail()
    }
    // 这里的res即返回数据
    const saveFile = (file: Data) => {
      // demandApi.DemanddownloadSec(file.uid).then((res) => {
      //   let blob = new Blob([res], { type: 'application/png' })
      //   let downloadUrl = window.URL.createObjectURL(blob)
      //   console.log(downloadUrl)
      //   pageData.fileList[0].url = downloadUrl
      //   let a = document.createElement('a')
      //   a.href = downloadUrl as string
      //   a.setAttribute('download', file.name as string)
      //   a.click()
      // })
      // window.location.href = file.url as string
      // window.open('api/file/preview?id=-1005583576658656941', '_self')
      // demandApi
      //   .Demanddownload({ id: file.uid })
      //   .then((res) => {
      //     console.log(res)
      //     const blob = new Blob([res])
      //     const downloadUrl = window.URL.createObjectURL(blob)
      //     const anchor = document.createElement('a')
      //     anchor.href = downloadUrl
      //     anchor.download = decodeURI(file.name as string)
      //     anchor.click()
      //     // 下载完成移除元素
      //     document.body.removeChild(anchor)
      //     // 释放掉blob对象
      //     window.URL.revokeObjectURL(downloadUrl)
      //   })
      //   .catch((err) => {
      //     message.error(err)
      //   })
      let a = document.createElement('a')
      a.href = `/api/file/preview?id=${file.uid}` as string
      a.setAttribute('download', file.name as string)
      a.click()
    }
    return {
      ...toRefs(pageData),
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      optionList,
      submit,
      demandDetailFormRef,
      reset,
      saveFile,
      buttonObj,
    }
  },
})
