
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    selectListShow: {
      type: Boolean,
      default: true,
    },
    tabList: {
      type: Object,
      defaule: () => {
        return []
      },
    },
  },
  emits: ['tabChange'],
  setup(props, { emit }) {
    const tabChange = (activeKey: string) => {
      emit('tabChange', activeKey)
    }
    const activeKey = ref('1')
    return {
      tabChange,
      activeKey,
    }
  },
})
